import 'regenerator-runtime/runtime';
import { Wallet } from './near-wallet';

// When creating the wallet you can optionally ask to create an access key
// Having the key enables to call non-payable methods without interrupting the user to sign

// Yockto 50000000000000000000000
// const CONTRACT_ADDRESS = process.env.CONTRACT_NAME || "coinflip.near-examples.testnet"
const CONTRACT_ADDRESS = process.env.CONTRACT_ADDRESS || "flip.wojaks.near"
const TOKEN_ID = "wojak.tkn.near";
const wallet = new Wallet({ createAccessKeyFor: CONTRACT_ADDRESS })

const FEE = 0.02;

let chosenOptionAmount = null;
let chosenOptionId = null;

// Setup on page load
window.onload = async () => {
  let isSignedIn = await wallet.startUp();

  if (isSignedIn) {
    signedInFlow();
  } else {
    signedOutFlow();
  }

document.querySelectorAll('#signed-in-flow-menu .btn-option').forEach(button => {
  button.addEventListener('click', function() {

    document.querySelectorAll('.btn-option').forEach(btn => {
      btn.classList.remove('highlighted');
    });

    this.classList.add('highlighted');


    // Log the ID of the clicked button
    console.log(`Button with ID ${this.id} was clicked.`);

    // Use regex to find the number before "MIL"
    const regex = /\d+(\.\d+)?(?=\s*MIL)/;
    const matches = this.textContent.match(regex);

    if (matches && matches.length > 0) {
      const numberBeforeMil = parseFloat(matches[0]); // Convert to number
      console.log(`Extracted Number: ${numberBeforeMil}`); // Demonstrate extraction
      chosenOptionAmount = numberBeforeMil; // Assuming 'chosenOption' is defined elsewhere and used later
      chosenOptionId = this.id;

      // Here, 'chosenOption' could be used for further logic, such as making calculations,
      // updating the UI, or performing transactions based on the selected option.
    } else {
      console.log("No number found before MIL");
    }
  });
});


};

// Button clicks
const $get = (e) => document.querySelector(e);
$get('#sign-in-button').addEventListener('click', () => wallet.signIn());
$get('#sign-out-button').addEventListener('click', () => wallet.signOut());
$get('#choose-jeet').addEventListener('click', () => player_choose('jeet', chosenOptionId, chosenOptionAmount));
$get('#choose-yeet').addEventListener('click', () => player_choose('yeet', chosenOptionId, chosenOptionAmount));

// Executed when the player chooses a side
async function player_choose(side, optionId, optionAmount) {
  reset_buttons();
  $get(`#choose-${side}`).style.border = "1px solid rgb(48, 255, 122)";
  $get(`#choose-${side}`).style.boxShadow = "0 0 15px rgb(48, 255, 122)";
  start_flip_animation();
  set_status("Asking the contract to flip a coin");

  // If no option is selected, alert the user and stop further execution
  if (optionId === null) {
    window.alert("Please select the option!");
    stop_flip_animation_in("yeet"); // Consider stopping the flip animation if it's started regardless
    return; // Early return to stop execution
  }

  // Proceed with the coin flip logic if option is selected

  // First make parse the selected option to a token price representation:
  let optionAmount_formated = formatToken(optionAmount);

  console.log(optionAmount, optionAmount_formated)

  // Add fee
  let fee = addFee(optionAmount_formated, FEE);

  let outcome = await wallet.callMethod({
    contractId: TOKEN_ID,
    method: 'ft_transfer_call',
    args: { 
      receiver_id: CONTRACT_ADDRESS, 
      amount: (optionAmount_formated + fee).toString(),
      msg: `[\"${side}\", \"${optionId}\"]`
    },
    // deposit: "50000000000000000000000"
    deposit: "1"
  });

  let string_result = outcome["receipts_outcome"][1]["outcome"]["logs"][1];
  console.log(string_result);
  const parts = string_result.split(":");
  const result = parts[1].trim().split(" ")[0];  
  
  // Update UI based on the outcome
  set_status(`The outcome was ${result}`);
  stop_flip_animation_in(result);

  if (result === side) {
    set_status("You were right, you win a point!");
    $get(`#choose-${side}`).style.backgroundColor = "green";
    generateConfetti();
  } else {
    set_status("You were wrong, you lost a point");
    $get(`#choose-${side}`).style.backgroundColor = "red";
    $get(`#choose-${side}`).style.border = "1px solid rgb(255,0,0)";
    $get(`#choose-${side}`).style.boxShadow = "0 0 15px rgb(255,0,0)";
  }

  // Fetch the new score
  fetchScore();
}


async function fetchScore() {
  // console.log(wallet.accountId)
  const score = await wallet.viewMethod({
    contractId: CONTRACT_ADDRESS,
    method: 'points_of',
    args: { player: wallet.accountId }
  });

  document.querySelectorAll('[data-behavior=points]').forEach(el => {
    el.innerText = score;
  });
}

// Display the signed-out-flow container
function signedOutFlow() {
  document.querySelectorAll('#signed-in-flow').forEach(el => {
    el.style.display = 'none';
  });

  document.querySelectorAll('#signed-out-flow').forEach(el => {
    el.style.display = 'block';
  });
  
  document.querySelectorAll('#signed-in-flow-menu').forEach(el => {
    el.style.display = 'none';
  });

  // animate the coin
  $get('#coin').style.animation = "flip 3.5s ease 0.5s";
}

// Displaying the signed in flow container and fill in account-specific data
function signedInFlow() {
  document.querySelectorAll('#signed-in-flow').forEach(el => {
    el.style.display = 'block';
  });
  document.querySelectorAll('#signed-out-flow').forEach(el => {
    el.style.display = 'none';
  });
  document.querySelectorAll('#signed-in-flow-menu').forEach(el => {
    el.style.display = 'grid';
  });
  document.querySelectorAll('[data-behavior=account-id]').forEach(el => {
    el.innerText = formatString(wallet.accountId);
  });

  fetchScore()
}

// Aux methods to simplify handling the interface
function set_status(message) {
  document.querySelectorAll('[data-behavior=status]').forEach(el => {
    el.innerText = message;
  });
}

function reset_buttons() {
  $get(`#choose-jeet`).style.backgroundColor = "var(--secondary)"
  $get(`#choose-yeet`).style.backgroundColor = "var(--secondary)"
  $get(`#choose-yeet`).style.border = "none";
  $get(`#choose-yeet`).style.boxShadow = "none";
  $get(`#choose-jeet`).style.border = "none";
  $get(`#choose-jeet`).style.boxShadow = "none";
}

function start_flip_animation() {
  $get('#coin').style.animation = 'flip 2s linear 0s infinite';
}

function stop_flip_animation_in(side) {
  $get('#coin').style.animation = `flip-${side} 1s linear 0s 1 forwards`;
}

function generateConfetti() {
  const colors = ['#FFC0CB', '#FFD700', '#8A2BE2', '#00FF00', '#FF4500', '#00BFFF']; // Example colors
  const confettiCount = 500; // Number of confetti pieces

  for (let i = 0; i < confettiCount; i++) {
    const confetti = document.createElement('div');
    confetti.className = 'confetti';
    document.body.appendChild(confetti);

    // Apply randomized styles for variety
    confetti.style.left = `${Math.random() * 100}vw`;
    confetti.style.backgroundColor = colors[Math.floor(Math.random() * colors.length)];
    confetti.style.opacity = Math.random();
    confetti.style.transform = `scale(${Math.random()})`;

    // Apply the animation with random properties for each confetti
    confetti.style.animation = `fall ${3 + Math.random() * 3}s linear ${Math.random() * 2}s forwards`;

    // Use 'animationend' event to remove confetti after the animation
    confetti.addEventListener('animationend', function() {
      confetti.remove();
    });
  }
}

function formatToken(amount) {
  const formattedAmount = BigInt(amount * 1000000) * BigInt(10)**BigInt(18);
  return formattedAmount;
}

function addFee(amount, fee){
  // Convert fee to an integer-based representation; e.g., 0.02 becomes 2
  const feeAsInt = BigInt(Math.round(fee * 100)); // Converts 0.02 to 2
  
  // Convert amount to BigInt in case it's not already
  
  // Calculate the fee in terms of BigInt, adjusting for the conversion above
  // Note: The division by 100 is done after multiplying to avoid losing precision
  const calculatedFee = amount * feeAsInt / BigInt(100);
  
  return calculatedFee;
}

function formatString(str) {
  if (str.length > 10) {
      return str.substring(0, 5) + '...' + str.substring(str.length - 5);
  }
  return str;
}