"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PublicKey = void 0;
const types_1 = require("@near-js/types");
const utils_1 = require("@near-js/utils");
const ed25519_1 = require("@noble/curves/ed25519");
const constants_1 = require("./constants");
function key_type_to_str(keyType) {
    switch (keyType) {
        case constants_1.KeyType.ED25519: return 'ed25519';
        default: throw new Error(`Unknown key type ${keyType}`);
    }
}
function str_to_key_type(keyType) {
    switch (keyType.toLowerCase()) {
        case 'ed25519': return constants_1.KeyType.ED25519;
        default: throw new Error(`Unknown key type ${keyType}`);
    }
}
/**
 * PublicKey representation that has type and bytes of the key.
 */
class PublicKey extends types_1.Assignable {
    /**
     * Creates a PublicKey instance from a string or an existing PublicKey instance.
     * @param value The string or PublicKey instance to create a PublicKey from.
     * @returns {PublicKey} The PublicKey instance.
     */
    static from(value) {
        if (typeof value === 'string') {
            return PublicKey.fromString(value);
        }
        return value;
    }
    /**
     * Creates a PublicKey instance from an encoded key string.
     * @param encodedKey The encoded key string.
     * @returns {PublicKey} The PublicKey instance created from the encoded key string.
     */
    static fromString(encodedKey) {
        const parts = encodedKey.split(':');
        let publicKey;
        let keyType = constants_1.KeyType.ED25519;
        if (parts.length === 1) {
            publicKey = parts[0];
        }
        else if (parts.length === 2) {
            publicKey = parts[1];
            keyType = str_to_key_type(parts[0]);
        }
        else {
            throw new Error('Invalid encoded key format, must be <curve>:<encoded key>');
        }
        const decodedPublicKey = (0, utils_1.baseDecode)(publicKey);
        if (decodedPublicKey.length !== constants_1.KeySize.SECRET_KEY) {
            throw new Error(`Invalid public key size (${decodedPublicKey.length}), must be ${constants_1.KeySize.SECRET_KEY}`);
        }
        return new PublicKey({ keyType, data: decodedPublicKey });
    }
    /**
     * Returns a string representation of the public key.
     * @returns {string} The string representation of the public key.
     */
    toString() {
        return `${key_type_to_str(this.keyType)}:${(0, utils_1.baseEncode)(this.data)}`;
    }
    /**
     * Verifies a message signature using the public key.
     * @param message The message to be verified.
     * @param signature The signature to be verified.
     * @returns {boolean} `true` if the signature is valid, otherwise `false`.
     */
    verify(message, signature) {
        switch (this.keyType) {
            case constants_1.KeyType.ED25519: return ed25519_1.ed25519.verify(signature, message, this.data);
            default: throw new Error(`Unknown key type ${this.keyType}`);
        }
    }
}
exports.PublicKey = PublicKey;
