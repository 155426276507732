"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.logWarning = void 0;
const logger_1 = require("../logger");
/** @deprecated */
function logWarning(...args) {
    const [message, ...optionalParams] = args;
    logger_1.Logger.warn(message, ...optionalParams);
}
exports.logWarning = logWarning;
